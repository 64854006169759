import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { StatisticDataType } from "./statistics.reducer";

export const selectStatistics = (state: RootState) => state.statistics;

export const selectStatisticsByMode = (isRegion: boolean) =>
  createSelector(selectStatistics, (state) =>
    isRegion ? state.region : state.country
  );

export const selectRegionsMap = createSelector(selectStatistics, (state) =>
  state.country.reduce<Record<string, StatisticDataType>>(
    (acc, item: StatisticDataType) => {
      if (!item.res_code) return acc;
      acc[item.res_code] = item;
      return acc;
    },
    {}
  )
);

export const selectCountryLegend = createSelector(selectStatistics, (state) =>
  state.country.reduce<Record<"demand" | "ratio", number[]>>(
    (acc, item) => {
      // ***
      // const demand = Math.max(1, item.result_demand + item.result_remote_demand);
      const demand = Math.max(1, item.resumecount + item.relocation);
      // ***
      // const ratio = Math.max(1, item.result_ratio);
      const ratio = Math.max(1, item.resumecount / item.relocation);
      acc.demand.push(demand);
      acc.ratio.push(ratio);
      return acc;
    },
    { demand: [], ratio: [] }
  )
);

export const selectCitiesMap = createSelector(selectStatistics, (state) =>
  state.region.reduce<Record<string, StatisticDataType>>(
    (acc, item: StatisticDataType) => {
      acc[item.res_name] = item;
      return acc;
    },
    {}
  )
);

export const selectRegion = (region: string) =>
  createSelector(selectStatistics, (state) => {
    return state.country.find((item) => {
      return item.res_code === region;
    });
  });

export const selectCity = (city?: string | null) =>
  createSelector(selectStatistics, (state) => {
    return state.region.find((item) => {
      return item.res_name === city;
    });
  });

export const selectSkillsStatistics = createSelector(
  selectStatistics,
  (state) => state.skills
);

// *** issue
export const selectPositionsStatistics = createSelector(
  selectStatistics,
  (state) => state.positions
);

// *** issue
export const selectFieldsStatistics = createSelector(
  selectStatistics,
  (state) => state.fields
);

import { FilterFilled, FilterOutlined } from "@ant-design/icons/lib";
import Button from "antd/lib/button";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { StatisticDataType } from "store/statistics/statistics.reducer";
import { selectStatisticsByMode } from "store/statistics/statistics.selectors";

import {
  RatingContainer,
  RatingFilter,
  RatingFilterItem,
  RatingList,
  RatingNone,
  RatingTitle,
} from "./Rating.styled";

import RatingItem from "./RatingItem";
import LegendChart from "./LegendChart";
import { FilterParams } from "hooks/filters.hooks";

const isRedesign = process.env.REACT_APP_THEME === "redesign";

type RegionChartProps = {
  searchParams: URLSearchParams;
  setFilter: (params: FilterParams) => void;
  region?: StatisticDataType;
};

const RegionChart: React.FC<RegionChartProps> = ({
  region,
  searchParams,
  setFilter,
}) => {
  const navigate = useNavigate();
  const statistics = useSelector(selectStatisticsByMode(!!region));

  const [mode, setMode] = useState<
    "norelocation" | "fullday" | "relocation" | "resumecount"
  >("resumecount");
  const [limit, setLimit] = useState(10);
  const [isShownFilters, setIsShownFilters] = useState(false);

  const toggleFilters = () => setIsShownFilters(!isShownFilters);

  const rating = useMemo(
    () =>
      statistics
        .filter(
          // *** (item) => item.result_demand > 0 || item.result_remote_demand > 0
          (item) => item.resumecount > 0 || item.relocation > 0
        )
        .sort((a, b) => {
          if (mode === "resumecount") {
            // *** return a.result_demand > b.result_demand ? -1 : 1;
            return a.resumecount > b.resumecount ? -1 : 1;
          } else {
            // *** return a.avg_salary > b.avg_salary ? -1 : 1;
            return a.fullday > b.fullday ? -1 : 1;
          }
        })
        .slice(0, limit),
    [mode, limit, statistics]
  );

  // Получаем максимальное значение и берем его за 100% длинны графика
  // ***
  // const maxValueSalary = Math.max(...rating.map((item) => item.avg_salary));
  const maxValueSalary = Math.max(...rating.map((item) => item.fullday));

  // ***
  // const maxValueDemand = rating[0]
  //   ? rating[0].result_demand + rating[0].result_remote_demand
  //   : 0;
  const maxValueDemand = rating[0]
    ? rating[0].resumecount + rating[0].relocation
    : 0;

  const handleLoadMore = useCallback(() => {
    const pagesCount = Math.floor(rating.length / 10);
    const currPage = Math.floor(limit / 10);
    if (pagesCount <= currPage) {
      setLimit(currPage * 10 + 10);
    }
  }, [limit, rating.length]);

  const handleSelect = useCallback(
    (res_code: string | number) => {
      const params = searchParams.toString();
      if (region) {
        setFilter({ city: res_code });
      } else {
        navigate(`/${res_code}?${params}`);
      }
    },
    [navigate, region, searchParams, setFilter]
  );

  const Filter = () =>
    isRedesign ? (
      <FilterFilled onClick={toggleFilters} />
    ) : (
      <FilterOutlined onClick={toggleFilters} />
    );

  const handleSetDemandFilter = () => setMode("resumecount");
  const handleSetSalaryFilter = () => setMode("fullday");

  return (
    <RatingContainer>
      <RatingTitle>
        <h3>
          Объем кадров в субъектах РФ <Filter />
        </h3>
      </RatingTitle>
      {isShownFilters && (
        <RatingFilter>
          <p>
            Сортировать по:
            <RatingFilterItem
              isActive={mode === "resumecount"}
              onClick={handleSetDemandFilter}
            >
              количеству резюме
            </RatingFilterItem>
            <RatingFilterItem
              isActive={mode === "fullday"}
              onClick={handleSetSalaryFilter}
            >
              зарплате за полный день
            </RatingFilterItem>
          </p>
        </RatingFilter>
      )}
      <RatingList>
        {rating.length > 0 ? (
          rating
            .sort(
              (a, b) =>
                b.resumecount + b.relocation - (a.resumecount + a.relocation)
            )
            .map((item) => (
              <RatingItem
                key={item.res_id}
                bar={item}
                maxDemand={maxValueDemand}
                maxSalary={maxValueSalary}
                onSelect={handleSelect}
                rowId={region ? "res_name" : "res_code"}
              />
            ))
        ) : (
          <RatingNone>Нет данных</RatingNone>
        )}
      </RatingList>
      {statistics.length >= 10 && (
        <Button type="link" block onClick={handleLoadMore}>
          Показать больше
        </Button>
      )}
      {rating.length > 0 && <LegendChart />}
    </RatingContainer>
  );
};

export default React.memo(RegionChart, (a, b) => a.region === b.region);

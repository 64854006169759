import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FieldAPI } from "../../services/fields.services";
import { IState } from "../../types/NetworkState";

export interface IField {
  id: number;
  parent_id: number;
  s_name: string;
  uid: string;
}

const initialState: IState<IField> = {
  duration: 0,
  entities: [],
  loading: "idle",
};

export const fetchFields = createAsyncThunk("fields/fetch", async () => {
  const entities = await FieldAPI.fetch();

  // normalize
  const normalizedEntities = entities.map((entity) => {
    return { ...entity, parent_id: 0 };
  });

  return {
    entities: normalizedEntities,
  };
});

const fieldsSlice = createSlice({
  name: "fields",
  initialState,
  reducers: {
    setFields(state, action: PayloadAction<IField[]>) {
      return { ...state, entities: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFields.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: "done" };
      })
      .addCase(fetchFields.pending, (state) => {
        return { ...state, loading: "pending" };
      });
  },
});

const { actions, reducer } = fieldsSlice;

export const { setFields } = actions;

export default reducer;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FilterFilled, FilterOutlined } from "@ant-design/icons/lib";
import { Selector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import Button from "antd/lib/button";

import { RootState } from "store";
import { StatisticDataType } from "store/statistics/statistics.reducer";
import { MapMode } from "types/MapMode";
import { FilterParams } from "hooks/filters.hooks";

import {
  RatingContainer,
  RatingFilter,
  RatingFilterItem,
  RatingList,
  RatingNone,
  RatingTitle,
} from "./Rating.styled";
import RatingItem from "./RatingItem";
import LegendChart from "./LegendChart";

const isRedesign = process.env.REACT_APP_THEME === "redesign";

type RegularChartProps = {
  mapMode: MapMode;
  title: string;
  filterKey: string;
  filter: FilterParams;
  region?: StatisticDataType;
  city?: StatisticDataType;
  selector: Selector<RootState, StatisticDataType[], any>;
  action: any;
  setFilter: (params: FilterParams) => void;
};

const RegularChart: React.FC<RegularChartProps> = ({
  title,
  region,
  selector,
  action,
  mapMode,
  filterKey,
  filter,
  setFilter,
  city,
}) => {
  const dispatch = useDispatch();
  const statistics = useSelector(selector);

  const [mode, setMode] = useState<
    "norelocation" | "fullday" | "relocation" | "resumecount"
  >("resumecount");
  const [limit, setLimit] = useState(10);
  const [isShownFilters, setIsShownFilters] = useState(false);

  useEffect(() => {
    let region_id;

    if (region?.res_id) {
      region_id = region.res_id;
    }

    if (city?.res_id) {
      region_id = city.res_id;
    }

    dispatch(
      action({
        region_id,
        limit,
        mapMode,
        filters: filter,
        filter: mode,
      })
    );
  }, [
    dispatch,
    mode,
    limit,
    mapMode,
    city?.res_id,
    region?.res_id,
    action,
    filter,
  ]);

  const toggleFilters = () => setIsShownFilters(!isShownFilters);

  const rating = useMemo(
    () =>
      statistics
        .filter(
          // ***
          // (item) => item.result_demand > 0 || item.result_remote_demand > 0
          (item) => item.resumecount > 0 || item.relocation > 0
        )
        .sort((a, b) => {
          if (mode === "resumecount") {
            // ***
            // return a.result_demand > b.result_demand ? -1 : 1;
            return a.resumecount > b.resumecount ? -1 : 1;
          } else {
            // ***
            // return a.avg_salary > b.avg_salary ? -1 : 1;
            return a.fullday > b.fullday ? -1 : 1;
          }
        }),
    [mode, statistics]
  );

  // Получаем максимальное значение и берем его за 100% длинны графика
  // ***
  // const maxValueSalary = Math.max(...rating.map((item) => item.avg_salary));
  const maxValueSalary = Math.max(...rating.map((item) => item.fullday));
  // ***
  // const maxValueDemand = rating[0]
  //   ? rating[0].result_demand + rating[0].result_remote_demand
  //   : 0;
  const maxValueDemand = rating[0]
    ? rating[0].resumecount + rating[0].relocation
    : 0;

  const handleLoadMore = useCallback(() => {
    const pagesCount = Math.floor(rating.length / 10);
    const currPage = Math.floor(limit / 10);
    if (pagesCount <= currPage) {
      setLimit(currPage * 10 + 10);
    }
  }, [limit, rating.length]);

  const handleSelect = useCallback(
    (res_id: string | number) => {
      setFilter({ [filterKey]: res_id });
    },
    [filterKey, setFilter]
  );

  const Filter = () =>
    isRedesign ? (
      <FilterFilled onClick={toggleFilters} />
    ) : (
      <FilterOutlined onClick={toggleFilters} />
    );

  const handleSetDemandFilter = () => setMode("resumecount");
  const handleSetSalaryFilter = () => setMode("fullday");

  if (filter[filterKey]) return null;

  return (
    <RatingContainer>
      <RatingTitle>
        <h3>
          {title} <Filter />
        </h3>
      </RatingTitle>
      {isShownFilters && (
        <RatingFilter>
          <p>
            Сортировать по:
            <RatingFilterItem
              isActive={mode === "resumecount"}
              onClick={handleSetDemandFilter}
            >
              количеству резюме
            </RatingFilterItem>
            <RatingFilterItem
              isActive={mode === "fullday"}
              onClick={handleSetSalaryFilter}
            >
              зарплате за полный день
            </RatingFilterItem>
          </p>
        </RatingFilter>
      )}
      <RatingList>
        {rating.length > 0 ? (
          rating.map((item) => (
            <RatingItem
              key={item.res_id}
              bar={item}
              maxDemand={maxValueDemand}
              maxSalary={maxValueSalary}
              onSelect={handleSelect}
              rowId="res_id"
            />
          ))
        ) : (
          <RatingNone>Нет данных</RatingNone>
        )}
      </RatingList>
      {statistics.length >= 10 && (
        <Button type="link" block onClick={handleLoadMore}>
          Показать больше
        </Button>
      )}
      {rating.length > 0 && <LegendChart />}
    </RatingContainer>
  );
};

export default React.memo(RegularChart);

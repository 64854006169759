import React from "react";
import Spin from "antd/lib/spin";

import MapAbout from "./MapAbout";
import Vacancies from "../Vacancies/Vacancies";
import SelectMapRegion from "./SelectMapRegion";
import { MapHeaderButton, MapHeaderContainer } from "./MapHeader.styled";

const isRedesign = process.env.REACT_APP_THEME === "redesign";

const MapHeader = () => {
  return (
    <MapHeaderContainer>
      <MapHeaderButton>
        <SelectMapRegion />
      </MapHeaderButton>
      <React.Suspense fallback={<Spin />}>
        <MapHeaderButton>
          <Vacancies />
        </MapHeaderButton>
        {/* <MapHeaderButton>
          <Education />
        </MapHeaderButton> */}
        {isRedesign && <MapAbout />}
      </React.Suspense>
    </MapHeaderContainer>
  );
};

export default React.memo(MapHeader);

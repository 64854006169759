import Button from "antd/lib/button";
import { CloseOutlined } from "@ant-design/icons/lib/icons";
import { StatisticDataType } from "store/statistics/statistics.reducer";
import { SidebarTitle as Title } from "./Sidebar.styled";
type SidebarTitleProps = {
  city?: StatisticDataType;
  region?: StatisticDataType;
  onBackToRegion: () => void;
  onBackToCountry: () => void;
};
const SidebarTitle: React.FC<SidebarTitleProps> = ({
  city,
  region,
  onBackToCountry,
  onBackToRegion,
}) => {
  if (city) {
    return (
      <Title>
        {city.res_name}{" "}
        <Button icon={<CloseOutlined />} onClick={onBackToRegion} />
      </Title>
    );
  }
  if (region) {
    return (
      <Title>
        {region.res_name}{" "}
        <Button icon={<CloseOutlined />} onClick={onBackToCountry} />
      </Title>
    );
  }
  return null;
};

export default SidebarTitle;

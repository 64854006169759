import { useCallback, useMemo } from "react";

import { useSearchParams } from "react-router-dom";

export type FilterParams = { [key: string]: string | number };

// Оптимизировать в дальнейшем
export const useFilters = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const state = useMemo(() => {
    const filter: FilterParams = {};
    searchParams.forEach((value, key) => {
      filter[key] = !isNaN(+value) ? +value : value;
    });
    return filter;
  }, [searchParams]);

  const setState = useCallback(
    (params: FilterParams) => {
      const search = new URLSearchParams(searchParams);

      Object.keys(params).forEach((key) => {
        if (params[key]) {
          const value = params[key].toString();
          if (search.has(key)) {
            search.set(key, value);
          } else {
            search.append(key, value);
          }
        } else {
          search.delete(key);
        }
      });

      setSearchParams(search, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  const clear = useCallback(
    (key: string) => {
      let search = new URLSearchParams(searchParams);
      search.delete(key);
      setSearchParams(search);
    },
    [searchParams, setSearchParams]
  );

  return [state, setState, clear, searchParams] as const;
};

export const isFiltered = (filters: FilterParams) =>
  Object.keys(filters).length > 0;

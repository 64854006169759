import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Modal from "antd/lib/modal/Modal";
import Button from "antd/lib/button";

import { useFilters } from "hooks/filters.hooks";
import { useRegion } from "hooks/region.hooks";
import { fetchVacancies } from "store/vacancies/vacancies.reducer";
import { selectCity } from "store/statistics/statistics.selectors";

const VacanciesTable = React.lazy(() => import("./VacanciesTable"));

const Vacancies = () => {
  const region = useRegion();
  const [filter] = useFilters();
  const [search] = useSearchParams();
  const dispatch = useDispatch();
  const city = useSelector(selectCity(search.get("city")));
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isVisible) return;
    const region_id = city ? city.res_id : region?.res_id;
    dispatch(fetchVacancies({ filters: filter, region_id }));
  }, [city, dispatch, filter, isVisible, region?.res_id]);

  const handleOpen = useCallback(() => {
    setIsVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  return (
    <>
      <Button type="default" onClick={handleOpen}>
        Показать резюме
      </Button>
      <Modal
        visible={isVisible}
        onCancel={handleClose}
        footer={null}
        width={1200}
      >
        <VacanciesTable
          region={region?.res_name}
          city={city?.res_name}
          filter={filter}
        />
      </Modal>
    </>
  );
};

export default React.memo(Vacancies);

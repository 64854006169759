import React from "react";
import { RatingLegend, RatingLegendItem } from "./Rating.styled";

const LegendChart = () => {
  return (
    <RatingLegend>
      <RatingLegendItem color="#26a0fc">
        Количество резюме, не готов к переезду
      </RatingLegendItem>
      <RatingLegendItem color="#1585da">
        Количество резюме, готов к переезду
      </RatingLegendItem>
      <RatingLegendItem color="#26e7a6">
        Средняя заработная плата (полный день или смена, руб.)
      </RatingLegendItem>
      <RatingLegendItem color="#d0d0d0">НД - нет данных</RatingLegendItem>
    </RatingLegend>
  );
};

export default React.memo(LegendChart);
